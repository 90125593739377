import React, { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router';
import { isAllowedForResourceAndAction, isSuperAdmin } from '../../features/user/userSlice';
import { Drawer } from '../../components/Drawer';
import { SidebarLink, SidebarNavigationLink } from '../../components/SidebarNavigationLink';
import PeopleIcon from '@material-ui/icons/People';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VpnKeyOutlined from '@material-ui/icons/VpnKey';
import LockOpen from '@material-ui/icons/LockOpen';
import Layers from '@material-ui/icons/Layers';
import SettingsRemote from '@material-ui/icons/SettingsRemote';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { Divider } from '../../components/Divider';
import Archive from '@material-ui/icons/Archive';
import SupportIcon from '@material-ui/icons/Reorder';
import { isSyncAllowed } from '../../features/members/utils';
import SyncIcon from '@material-ui/icons/Sync';
import { calcSyncLogTitle } from '../../features/sync_log/utils';
import HelpIcon from '@material-ui/icons/Help';
import { SidebarNavigation } from '../../components/SidebarNavigation';
import Settings from '@material-ui/icons/Settings';
import { useSelector } from 'react-redux';
import { getAccessPointsWithKeypads } from '../../features/access_points/selectors';
import { ICommunity } from '../../app/domain/ICommunity';
import HttpsIcon from '@material-ui/icons/Https';
import FocusIcon from '@material-ui/icons/CenterFocusWeak';
import DirectionsWalk from '@material-ui/icons/DirectionsWalk';
import Videocam from '@material-ui/icons/Videocam';
import betaLabel from '../../stories/assets/beta-label.svg';
import { makeStyles } from '@material-ui/styles';

type User = { profile: { role: string; memberships: never[] } };

export function NavBar({
  openMobile,
  onMobileClose,
  user,
  community,
}: PropsWithChildren<{
  openMobile: boolean;
  onMobileClose: () => void;
  user: User;
  community: ICommunity;
}>): JSX.Element {
  const location = useLocation();
  const accessPointsWithKeypads = useSelector(getAccessPointsWithKeypads);
  const hasFullAccess = isAllowedForResourceAndAction(user.profile, 'members', 'addFullAccess', community.id);
  const classes = useStyles();

  const newLinksToPortalV3: { [key: string]: string | undefined } = {
    cameras:
      process.env.PORTAL_V3_HOST && `${process.env.PORTAL_V3_HOST}/managements/communities/${community.id}/cameras`,
    cameraEvents:
      process.env.PORTAL_V3_HOST &&
      `${process.env.PORTAL_V3_HOST}/managements/communities/${community.id}/camera-events`,
    access_log:
      process.env.PORTAL_V3_HOST && `${process.env.PORTAL_V3_HOST}/managements/communities/${community.id}/access-log`,
    support_log:
      process.env.PORTAL_V3_HOST && `${process.env.PORTAL_V3_HOST}/managements/communities/${community.id}/support-log`,
    users:
      process.env.PORTAL_V3_HOST &&
      `${process.env.PORTAL_V3_HOST}/managements/communities/${community.id}/users/residents`,
    flagged_users:
      process.env.PORTAL_V3_HOST &&
      `${process.env.PORTAL_V3_HOST}/managements/communities/${community.id}/users/flagged-users`,
    staff_users:
      process.env.PORTAL_V3_HOST && `${process.env.PORTAL_V3_HOST}/managements/communities/${community.id}/users/staff`,
    archived_users:
      process.env.PORTAL_V3_HOST &&
      `${process.env.PORTAL_V3_HOST}/managements/communities/${community.id}/users/archived-users`,
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <Drawer onClose={onMobileClose} openMobile={openMobile}>
      <SidebarNavigation>
        {(community.show_dashboard || isSuperAdmin(user.profile)) && (
          <SidebarNavigationLink icon={<DashboardIcon />} to={`/managements/communities/${community.id}/dashboard`}>
            Dashboard
          </SidebarNavigationLink>
        )}

        <SidebarNavigationLink
          icon={<PeopleIcon />}
          to={newLinksToPortalV3['users'] || `/managements/communities/${community.id}/users`}
        >
          Users
        </SidebarNavigationLink>
        <SidebarNavigationLink
          icon={<PersonOutlineIcon />}
          to={newLinksToPortalV3['flagged_users'] || `/managements/communities/${community.id}/flagged_users`}
        >
          Flagged Users
        </SidebarNavigationLink>
        <SidebarNavigationLink
          icon={<SupervisorAccountIcon />}
          to={newLinksToPortalV3['staff_users'] || `/managements/communities/${community.id}/staff_users`}
        >
          Staff Users
        </SidebarNavigationLink>
        <SidebarNavigationLink icon={<VpnKeyOutlined />} to={`/managements/communities/${community.id}/gates`}>
          Gates & Doors
        </SidebarNavigationLink>
        <SidebarNavigationLink
          icon={<Videocam />}
          to={newLinksToPortalV3['cameras'] || `/managements/communities/${community.id}/cameras`}
          redirectToPortalV3={!!newLinksToPortalV3['cameras']}
        >
          Camera Settings <img className={classes.betaLabel} src={betaLabel} alt="(Beta)" />
        </SidebarNavigationLink>
        <SidebarNavigationLink
          icon={<FocusIcon />}
          to={newLinksToPortalV3['cameraEvents'] || `/managements/communities/${community.id}/camera-rpi-events`}
          redirectToPortalV3={!!newLinksToPortalV3['cameraEvents']}
        >
          Camera Events <img className={classes.betaLabel} src={betaLabel} alt="(Beta)" />
        </SidebarNavigationLink>
        <SidebarNavigationLink
          icon={<LockOpen />}
          to={newLinksToPortalV3['access_log'] || `/managements/communities/${community.id}/access_log`}
        >
          Access Log
        </SidebarNavigationLink>
        {isSuperAdmin(user.profile) && (
          <SidebarNavigationLink icon={<Layers />} to={`/managements/communities/${community.id}/groups`}>
            Groups
          </SidebarNavigationLink>
        )}
        <SidebarNavigationLink icon={<SettingsRemote />} to={`/managements/communities/${community.id}/remotes`}>
          Remotes
        </SidebarNavigationLink>
        {hasFullAccess && accessPointsWithKeypads.length > 0 && (
          <SidebarNavigationLink icon={<KeyboardIcon />} to={`/managements/communities/${community.id}/keypads`}>
            Keypads
          </SidebarNavigationLink>
        )}
        <SidebarNavigationLink icon={<HttpsIcon />} to={`/managements/communities/${community.id}/unit_locks`}>
          Unit Locks <img className={classes.betaLabel} src={betaLabel} alt="(Beta)" />
        </SidebarNavigationLink>
        <SidebarNavigationLink icon={<DirectionsWalk />} to={`/managements/communities/${community.id}/tours/booked`}>
          Self-Guided Tours <img className={classes.betaLabel} src={betaLabel} alt="(Beta)" />
        </SidebarNavigationLink>
        <SidebarNavigationLink
          icon={<VpnKeyOutlined />}
          to={`/managements/communities/${community.id}/prospects_access`}
        >
          Prospects Access
        </SidebarNavigationLink>
        <SidebarNavigationLink icon={<VpnKeyOutlined />} to={`/managements/communities/${community.id}/vendors_access`}>
          Vendors Access
        </SidebarNavigationLink>
        {community.quickbooks_id && (
          <SidebarNavigationLink icon={<ReceiptIcon />} to={`/managements/communities/${community.id}/billing`}>
            Billing
          </SidebarNavigationLink>
        )}

        <SidebarNavigationLink icon={<Settings />} to={`/managements/communities/${community.id}/settings`}>
          Settings
        </SidebarNavigationLink>

        <Divider />

        <SidebarNavigationLink
          icon={<Archive />}
          to={newLinksToPortalV3['archived_users'] || `/managements/communities/${community.id}/archived_users`}
        >
          Archived Users
        </SidebarNavigationLink>

        {isAllowedForResourceAndAction(user.profile, 'supportLog', 'view', community.id) && (
          <SidebarNavigationLink
            icon={<SupportIcon />}
            to={newLinksToPortalV3['support_log'] || `/managements/communities/${community.id}/detailed_access_log`}
          >
            Support Log
          </SidebarNavigationLink>
        )}

        {isAllowedForResourceAndAction(user.profile, 'syncLog', 'view', community.id) &&
          isSyncAllowed(community.integration) && (
            <SidebarNavigationLink icon={<SyncIcon />} to={`/managements/communities/${community.id}/sync_log`}>
              {calcSyncLogTitle(community)}
            </SidebarNavigationLink>
          )}

        <SidebarLink icon={<HelpIcon />} to={`https://ww1.gatewise.com/welcome?utm_source=portal`}>
          Welcome Center
        </SidebarLink>
      </SidebarNavigation>
    </Drawer>
  );
}

const useStyles = makeStyles(() => ({
  betaLabel: {
    marginLeft: '8px',
    height: '14px',
  },
}));
